import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-songs',
  templateUrl: './songs.component.html',
  styleUrls: ['./songs.component.css']
})
export class SongsComponent implements OnInit {

sideNav:boolean;


  constructor() {
 
 
   }


  ngOnInit() {
  }

}


	



