import { Component, OnInit,OnChanges,AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-ed',
  templateUrl: './ed.component.html',
  styleUrls: ['./ed.component.css']
})
export class EdComponent implements OnInit {
  
showLoader: boolean = true;
ngIfaudio :boolean;
time:any;
currentTime:any;
ontimeupdate:any;
number :any;
minValue:any;
maxValue:any;
y:any;
x:any;
value:any;
audioD:any;
ifPlay:boolean;
playShowStyle:boolean;
showStyleP:boolean;
showDrumeStyle:boolean;
showBassStyle:boolean;
showGuitarStyle:boolean;
showPianoStyle :boolean;
showStringStyle: boolean;
showSingerStyle:boolean;
showMuteStyle:boolean;
numLike:any;
numUnLike:any;
volume:any;
RangeVolume:any;
RangeVolumeD:any;
RangeVolumeB:any;
RangeVolumeG:any;
RangeVolumeP:any;
RangeVolumeS:any;

vol:number;
nCnt: number = 0;
nCntU:number= 0;
nCnt1: number = 0;
sideNav:boolean;
muted:boolean;
ifPuse:boolean = false;
showChord:boolean;
showChordD :boolean;
showChordDsharp :boolean;
showChordDm:boolean;
showSimpleChordD:boolean;
showChordCadd9:boolean;
showChordCsharp:boolean;
showChordB:boolean;
showChordBb:boolean;
showChordBm:boolean;
showChordA:boolean;
showChordAm:boolean;
showChordC:boolean;
showChordEm:boolean;
showChordE:boolean;
showChordFdm7:boolean;
showChordF:boolean;
showChordFm:boolean;
showChordFDm:boolean;
showChordFmaj7:boolean;
showChordDandFD:boolean;
showChordGmaj7:boolean;
showGsharp:boolean;
showChordG:boolean;
SimpleShowChordG:boolean;
showChordGm:boolean;
showChordAm7:boolean;
showChordAa:boolean;
showChordBm7:boolean;

marqee:any;
visit:any;

val:number;
R:number;

slider:any;
myRange:any;









updateTime(audioD){
  
 var x = this.time = audioD.currentTime ;
}



like(){
  this.nCnt = this.nCnt + 1 ;
  this.numLike =  this.nCnt;
}


unLike(){
  this.nCntU = this.nCntU + 1 ;
  this.numUnLike =  this.nCntU;

}

view(bodyView){
  this.nCnt1 = this.nCnt1 += 1 ;
  this.visit =  this.nCnt1;


}
updateVolumeD(audioD){
  audioD.volume = this.RangeVolumeD /100;
}

updateVolumeB(audioB){
  audioB.volume = this.RangeVolumeB /100;
}

updateVolumeG(audioG){
  audioG.volume = this.RangeVolumeG /100;
}

updateVolumeP(audioP){
  audioP.volume = this.RangeVolumeP /100;
}

updateVolumeS(audioS){
  audioS.volume = this.RangeVolumeS /100;
}

updateVolume(audioD,audioB,audioG,audioP,audioS,string){
  audioD.volume =  this.RangeVolume /100;
  audioB.volume = this.RangeVolume /100 ;
  audioG.volume = this.RangeVolume /100 ;
  audioP.volume = this.RangeVolume /100;
  audioS.volume = this.RangeVolume /100 ;
  string.volume = this.RangeVolume /100;

}

 set(event){
console.log(event);
}

 muteAll(audioD,audioB,audioG,audioP,audioS,string){
if(audioB.muted == true && audioG.muted == true && audioD.muted == true && audioP.muted == true && audioS.muted == true && string == true )
  
  
{
  audioB.muted = false;
  audioG.muted = false;
  audioD.muted = false;
  audioP.muted = false;
  audioS.muted = false;
  string.muted = false;

}else{
  
  audioB.muted = true;
  audioG.muted = true;
  audioD.muted = true;
  audioP.muted = true;
  audioS.muted = true;
  string.muted = true;
  }
}



muteD(audioD){
 if(audioD.muted == true){
  audioD.muted = false;
 }else{
  audioD.muted = true; 
  }
 }


muteB(audioB){
 if(audioB.muted == true){
  audioB.muted = false;
 }else{
  audioB.muted = true; 
  }
 }

 muteG(audioG){
 if(audioG.muted == true){
  audioG.muted = false;
 }else{
  audioG.muted = true; 
  }
 }

 muteP(audioP){
 if(audioP.muted == true){
  audioP.muted = false;
 }else{
  audioP.muted = true; 
  }
 }

 muteString(string){
 if(string.muted == true){
  string.muted = false;
 }else{
  string.muted = true; 
  }
 }

 
muteS(audioS){
if(audioS.muted == true){
  audioS.muted = false;
  }else{
  audioS.muted = true;
  }
 }

play(audioD,audioB,audioG,audioP,audioS,string){

  audioD.play();
  audioB.play();
  audioG.play();
  audioP.play();
  audioS.play();
  string.play();

}

puse(audioD,audioB,audioG,audioP,audioS,string){
  audioD.pause();
  audioB.pause();
  audioG.pause();
  audioP.pause();
  audioS.pause();
  string.pause();

}

repeat(audioD,audioB,audioG,audioP,audioS,string){

  audioD.loop = true; audioD.load();
  audioB.loop = true; audioB.load();
  audioG.loop = true; audioG.load();
  audioP.loop = true; audioP.load();
  audioS.loop = true; audioS.load();
  string.loop = true; string.load();

}

goToStart(audioD,audioB,audioG,audioP,audioS,string){
  
  audioD.autoplay = true;audioD.load();
  audioB.autoplay = true;audioB.load();
  audioG.autoplay = true;audioG.load();
  audioP.autoplay = true;audioP.load();
  audioS.autoplay = true;audioS.load();
  string.autoplay = true;string.load();
}




  constructor() {
       this.minValue = this.y;
       this.maxValue = this.x;
       this.number = this.y;
       this.time = "0" ;
       this.RangeVolume = 100;
       this.RangeVolumeS = 80;
      this.RangeVolumeD = 80;
      this.RangeVolumeB = 80;
      this.RangeVolumeG = 80;
      this.RangeVolumeP = 80;



       this.vol = this.RangeVolume;
        
       

       this.sideNav = true;
       
       this.numLike ="0";
       this.numUnLike ="0";
    
       this.visit = "0";

       this.showChord = false;
        
       this.ngIfaudio = true; 

       this.ifPlay = true;

   }



showMute(){
if(this.showMuteStyle ) {
      return "#ff004c";
    } else {
      return "silver";
    }
  }


 

showVolume(){
if(this.showMuteStyle ) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }




   playStyle() {
    if(this.playShowStyle ) {
      return "black";
    } else {
      return "#ff004c";
    }
  }

puseStyle() {
    if(this.playShowStyle ) {
      return "#ff004c";
    } else {
      return "black";
    }
  }

drumStyleEvent() {
    if(this.showDrumeStyle) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }

bassStyleEvent() {
    if(this.showBassStyle) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }

guitarStyleEvent() {
    if(this.showGuitarStyle) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }

pianoStyleEvent() {
    if(this.showPianoStyle ) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }

  stringStyleEvent(){
    if(this.showStringStyle ) {
      return "silver";
    } else {
      return "#ff004c";
    }


  }
  
singerStyleEvent() {
    if(this.showSingerStyle ) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }
  

displayMute(){
    if(this.ngIfaudio == true){

      this.ngIfaudio = false;
    }else{

      this.ngIfaudio = true;
    }
}






displayPuse(){
    
if(this.ifPlay == false ){

      this.ifPlay = true
    }else{

      this.ifPlay = false  ;
    } 
    
}

displayPuse2(){
    
if(this.ifPuse == false ){

      this.ifPuse = true
    }else{

      this.ifPuse = false  ;
    } 
    
}



displayPlay(){

if(this.ifPuse == true ){

      this.ifPuse = false  ;
    }else{

      this.ifPuse = true ;
    }
    
    
}

displayPlay2(){

if(this.ifPlay == true ){

      this.ifPlay = false  ;
    }else{

      this.ifPlay = true ;
    }
    
    
}


showEm7(){
  if(this.showChord == true){

    this.showChord = false;

  }else{
    this.showChord = true;

  }


}

showD(){
  if(this.showChordD == true){

    this.showChordD = false;

  }else{
    this.showChordD = true;

  }
}

ShowD(){
  if(this.showSimpleChordD == true){

    this.showSimpleChordD = false;

  }else{
    this.showSimpleChordD = true;

  }
}

showDsharp(){
  if(this.showChordDsharp == true){

    this.showChordDsharp = false;

  }else{
    this.showChordDsharp = true;

  }
}

showDm(){
  if(this.showChordDm == true){

    this.showChordDm = false;

  }else{
    this.showChordDm = true;

  }
}

showEm(){
  if(this.showChordEm == true){

    this.showChordEm = false;

  }else{
    this.showChordEm = true;

  }
}

showE(){
  if(this.showChordE == true){

    this.showChordE = false;

  }else{
    this.showChordE = true;

  }
}

showCadd9(){
  if(this.showChordCadd9 == true){

    this.showChordCadd9 = false;

  }else{
    this.showChordCadd9 = true;

  }
}


showG(){
  if(this.showChordG == true){

      this.showChordG = false;

  }else{
      this.showChordG = true;

  }
}

ShowG(){
  if(this.SimpleShowChordG == true){

      this.SimpleShowChordG = false;

  }else{
      this.SimpleShowChordG = true;

  }
}


ShowGsharp(){
  if(this.showGsharp == true){

      this.showGsharp = false;

  }else{
      this.showGsharp = true;
  }
}


showB(){
  if(this.showChordB == true){

      this.showChordB = false;

  }else{
      this.showChordB = true;

  }
}

showBb(){
  if(this.showChordBb == true){

      this.showChordBb = false;

  }else{
      this.showChordBb = true;

  }
}


showA(){
  if(this.showChordA == true){

      this.showChordA = false;

  }else{
      this.showChordA = true;

  }
}

showAm(){
  if(this.showChordAm == true){

      this.showChordAm = false;

  }else{
      this.showChordAm = true;

  }
}

showC(){
  if(this.showChordC == true){

      this.showChordC = false;

  }else{
      this.showChordC = true;

  }
}

showCsharp(){
  if(this.showChordCsharp == true){

      this.showChordCsharp = false;

  }else{
      this.showChordCsharp = true;

  }
}


showFdm7(){
  if(this.showChordFdm7 == true){

      this.showChordFdm7 = false;

  }else{
      this.showChordFdm7 = true;

  }
}

showF(){
  if(this.showChordF == true){

      this.showChordF = false;

  }else{
      this.showChordF = true;

  }
}

showFm(){
  if(this.showChordFm == true){

      this.showChordFm = false;

  }else{
      this.showChordFm = true;

  }
}

showFmaj7(){
  if(this.showChordFmaj7 == true){

      this.showChordFmaj7 = false;

  }else{
      this.showChordFmaj7 = true;

  }
}

showFDm(){
  if(this.showChordFDm == true){

      this.showChordFDm = false;

  }else{
      this.showChordFDm = true;

  }
}

showDandFD(){
  if(this.showChordDandFD == true){

      this.showChordDandFD = false;

  }else{
      this.showChordDandFD = true;

  }
}

showGmaj7(){
  if(this.showChordGmaj7 == true){

      this.showChordGmaj7 = false;

  }else{
      this.showChordGmaj7 = true;

  }
}


showAnoBridg(){
  if(this.showChordAa == true){

      this.showChordAa = false;

  }else{
      this.showChordAa = true;

  }
}

showAm7(){
  if(this.showChordAm7 == true){

      this.showChordAm7 = false;

  }else{
      this.showChordAm7 = true;

  }
}

showGm(){
  if(this.showChordGm == true){

      this.showChordGm = false;

  }else{
      this.showChordGm = true;

  }
}

showBm7(){
  if(this.showChordBm7 == true){

      this.showChordBm7 = false;

  }else{
      this.showChordBm7 = true;

  }
}


showBm(){
  if(this.showChordBm == true){

      this.showChordBm = false;

  }else{
      this.showChordBm = true;

  }
}



  ngOnInit() {
    


  }



}
