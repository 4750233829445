import { Injectable } from '@angular/core';
import { Http , Response, RequestOptions, Headers } from '@angular/http';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable()
export class songsServices{

    private apiRoot = "http://marketdata.websol.barchart.com/getHistory.json?key=MY_API_KEY&";

/**
  constructor(private http: Http){
    console.log('albumsServices Initialized ...');
  }

   getAlbums() {
  
      return this.http.post("/albums")
     .map((response:Response) => response.json());


    }
**/


ngOnInit(){
	
}




}