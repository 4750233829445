import { Component } from '@angular/core';
import {songsServices} from './app.services';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers:[songsServices],
 
 
})


export class AppComponent {

Artists=["---","ed-sheren","Bruno-mars","madona","Areta-flack"];


HideFooter:boolean = true;

sideNav:boolean;
tooglePlayer:boolean;

ngIfaudio :boolean;
time:any;
currentTime:any;
ontimeupdate:any;
number :any;
minValue:any;
maxValue:any;
y:any;
x:any;
value:any;
audioD:any;
ifPlay:boolean;
playShowStyle:boolean;
showStyleP:boolean;
showDrumeStyle:boolean;
showBassStyle:boolean;
showGuitarStyle:boolean;
showPianoStyle :boolean;
showSingerStyle:boolean;
showMuteStyle:boolean;
numLike:any;
numUnLike:any;
volume:any;
vol:number;
nCnt: number = 0;
nCnt1: number = 0;
muted:boolean;
ifPuse:boolean = false;
visit:any;

albums: albums[];
      








updateTime(audioD){
 var x = this.time = audioD.currentTime ;
}



like(){
	this.nCnt = this.nCnt + 1 ;
	this.numLike =  this.nCnt;
}


unLike(){
	this.nCnt = this.nCnt + 1 ;
	this.numUnLike =  this.nCnt;

}

view(){
	this.nCnt1 = this.nCnt1 += 1 ;
	this.visit =  this.nCnt1;


}


updateVolume(audioD,audioB,audioG,audioP,audioS){
	audioD.volume =  this.vol;
 	audioB.volume = this.vol;
 	audioG.volume = this.vol;
 	audioP.volume = this.vol;
 	audioS.volume = this.vol;

}


 muteAll(audioD,audioB,audioG,audioP,audioS){
if(audioB.muted == true && audioG.muted == true && audioD.muted == true && audioP.muted == true && audioS.muted == true )
	
 	
{
	audioB.muted = false;
	audioG.muted = false;
	audioD.muted = false;
	audioP.muted = false;
	audioS.muted = false;

}else{
	
	audioB.muted = true;
	audioG.muted = true;
	audioD.muted = true;
	audioP.muted = true;
	audioS.muted = true;
	}
}



muteD(audioD){
 if(audioD.muted == true){
 	audioD.muted = false;
 }else{
 	audioD.muted = true; 
 	}
 }


muteB(audioB){
 if(audioB.muted == true){
 	audioB.muted = false;
 }else{
 	audioB.muted = true; 
 	}
 }

 muteG(audioG){
 if(audioG.muted == true){
 	audioG.muted = false;
 }else{
 	audioG.muted = true; 
 	}
 }

 muteP(audioP){
 if(audioP.muted == true){
 	audioP.muted = false;
 }else{
 	audioP.muted = true; 
 	}
 }

 
muteS(audioS){
if(audioS.muted == true){
 	audioS.muted = false;
 	}else{
 	audioS.muted = true;
 	}
 }

play(audioD,audioB,audioG,audioP,audioS){

	audioD.play();
 	audioB.play();
 	audioG.play();
 	audioP.play();
 	audioS.play();


}

puse(audioD,audioB,audioG,audioP,audioS){
	audioD.pause();
 	audioB.pause();
 	audioG.pause();
 	audioP.pause();
 	audioS.pause();


}

repeat(audioD,audioB,audioG,audioP,audioS){

	audioD.loop = true; audioD.load();
 	audioB.loop = true; audioB.load();
 	audioG.loop = true; audioG.load();
 	audioP.loop = true; audioP.load();
 	audioS.loop = true; audioS.load();


}

goToStart(audioD,audioB,audioG,audioP,audioS){
	
	audioD.autoplay = true;audioD.load();
 	audioB.autoplay = true;audioB.load();
 	audioG.autoplay = true;audioG.load();
 	audioP.autoplay = true;audioP.load();
 	audioS.autoplay = true;audioS.load();

}






constructor(private songsServices: songsServices){
	

 this.sideNav = false;
 this.tooglePlayer = true;

		this.minValue = this.y;
  		 this.maxValue = this.x;
  		 this.number = this.y;
  		 this.time = "0" ;
  		 this.volume = 0.7;
  		 this.vol = this.volume;
  			

  		 this.sideNav = true;
  		 
  		this.numLike ="0";
  		this.numUnLike ="0";
		
		this.visit = "0";

			
  			
		this.ngIfaudio = true;	

		this.ifPlay = true;


/*

this.songsServices.getAlbums().subscribe(albums => {
	this.albums = albums;
});
 
*/

}



showMute(){
if(this.showMuteStyle ) {
      return "#ff004c";
    } else {
      return "silver";
    }
  }



showVolume(){
if(this.showMuteStyle ) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }




   playStyle() {
    if(this.playShowStyle ) {
      return "black";
    } else {
      return "#ff004c";
    }
  }

puseStyle() {
    if(this.playShowStyle ) {
      return "#ff004c";
    } else {
      return "black";
    }
  }

drumStyleEvent() {
    if(this.showDrumeStyle) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }

bassStyleEvent() {
    if(this.showBassStyle) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }

guitarStyleEvent() {
    if(this.showGuitarStyle) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }

pianoStyleEvent() {
    if(this.showPianoStyle ) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }
  
singerStyleEvent() {
    if(this.showSingerStyle ) {
      return "silver";
    } else {
      return "#ff004c";
    }
  }
  

displayMute(){
		if(this.ngIfaudio == true){

			this.ngIfaudio = false;
		}else{

			this.ngIfaudio = true;
		}
}






displayPuse(){
		
if(this.ifPlay == false ){

			this.ifPlay = true
		}else{

			this.ifPlay = false  ;
		} 
		
}

displayPuse2(){
		
if(this.ifPuse == false ){

			this.ifPuse = true
		}else{

			this.ifPuse = false  ;
		} 
		
}



displayPlay(){

if(this.ifPuse == true ){

			this.ifPuse = false  ;
		}else{

			this.ifPuse = true ;
		}
		
		
}

displayPlay2(){

if(this.ifPlay == true ){

			this.ifPlay = false  ;
		}else{

			this.ifPlay = true ;
		}
		
		
}





  ngOnInit() {



  }


hideFooter(){

if(this.HideFooter == true ){

			this.HideFooter = false  ;
		}else{

			this.HideFooter = true ;
		}
}

showFooter(){
	if(this.HideFooter == false ){

			this.HideFooter = true  ;
		}else{

			this.HideFooter = false ;
		}
}




showPlayer(){
	if(this.tooglePlayer== false){
		this.tooglePlayer = true;

	}

}

hidePlayer(){
	if(this.tooglePlayer== true){
		this.tooglePlayer = false;

	}
}

hideNavSongs(){
	if(this.sideNav == true){
	this.sideNav = false;

	}

}


toogelsideNav(){
	if(this.sideNav == false){
	this.sideNav = true;
		}
	}

}


interface albums{
	
	title:string;
	
}
