import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AppComponent} from './app.component';
import {AboutComponent} from './about/about.component';
import {ServicesComponent} from './services/services.component';
import {ContactComponent} from './contact/contact.component';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {SongsComponent} from './songs/songs.component';
import {ToPlayComponent} from './to-play/to-play.component';
import {MetalicaComponent} from './chords-songs/metalica/metalica.component';
import {EricLaylaComponent} from './chords-songs/eric-layla/eric-layla.component';
import {EdComponent} from './chords-songs/ed/ed.component';
import {DuftPunkGetLuckyComponent} from './chords-songs/duft-punk-get-lucky/duft-punk-get-lucky.component';
import {BrunoVercacheComponent} from './chords-songs/bruno-vercache/bruno-vercache.component';
import {HereComponent} from './chords-songs/here/here.component';
import {MeroonGirlsLikeYouComponent} from './chords-songs/meroon-girls-like-you/meroon-girls-like-you.component';
import {NatlieImbruTornComponent} from './chords-songs/natlie-imbru-torn/natlie-imbru-torn.component';
import {NoDoubtDontSpeakComponent} from './chords-songs/no-doubt-dont-speak/no-doubt-dont-speak.component';
import {TheCranberriesZombieComponent} from './chords-songs/the-cranberries-zombie/the-cranberries-zombie.component';
import {RedHotCPCalifornicationComponent} from './chords-songs/red-hot-cp-californication/red-hot-cp-californication.component';
import {BillWithersAintNoSunshineComponent} from './chords-songs/bill-withers-aint-no-sunshine/bill-withers-aint-no-sunshine.component';
import {Maroon5SheWillLoveComponent} from './chords-songs/maroon5-she-will-love/maroon5-she-will-love.component';
import {NirvanaSmileLikeTeenSpiritComponent} from './chords-songs/nirvana-smile-like-teen-spirit/nirvana-smile-like-teen-spirit.component';
import {JohnLennonImagineComponent} from './chords-songs/john-lennon-imagine/john-lennon-imagine.component';
import {MagicRudeComponent} from './chords-songs/magic-rude/magic-rude.component';
import {JasonMrazComponent} from './chords-songs/jason-mraz/jason-mraz.component';
import {DireStraitsSultansOfSwingComponent} from './chords-songs/dire-straits-sultans-of-swing/dire-straits-sultans-of-swing.component';
import {PassengerLetHerGoComponent} from './chords-songs/passenger-let-her-go/passenger-let-her-go.component';
import {BobMarleyNoWomanNoCryComponent} from './chords-songs/bob-marley-no-woman-no-cry/bob-marley-no-woman-no-cry.component';  
import { LadyGagaShallowComponent } from './chords-songs/lady-gaga-shallow/lady-gaga-shallow.component';





export const router: Routes = [

	{path: '', redirectTo: '/home', pathMatch: 'full' },
	{path: 'about', component: AboutComponent},
	{path: 'services' , component: ServicesComponent},
	{path: 'contact' , component: ContactComponent},
	{path: 'login' , component: LoginComponent},
	{path: 'Home' , component: HomeComponent},
	{path: 'signUp' , component: SignUpComponent},
	{path: 'songs' ,component: SongsComponent},
	{path: 'toplay' ,component: ToPlayComponent},
	{path: 'metalica' ,component: MetalicaComponent },
	{path:'eric-layla', component:EricLaylaComponent},
	{path:'ed', component:EdComponent },
	{path:'DuftPunk-Get-Lucky', component:DuftPunkGetLuckyComponent },
	{path:'bruno-mars-vercache', component:BrunoVercacheComponent},
	{path:'here', component:HereComponent},
	{path:'meroon-girls-like-you', component:MeroonGirlsLikeYouComponent},
	{path:'natlie-imbru-torn', component:NatlieImbruTornComponent},
	{path:'no-doubt-dont-speak', component:NoDoubtDontSpeakComponent},
	{path:'the-cranberries-zombie', component:TheCranberriesZombieComponent},
	{path:'red-hot-cp-californication', component:RedHotCPCalifornicationComponent},
	{path:'bill-withers-aint-no-sunshine', component:BillWithersAintNoSunshineComponent},
	{path:'maroon5-she-will-love', component:Maroon5SheWillLoveComponent},
	{path:'nirvana-smile-like-teen-spirit', component:NirvanaSmileLikeTeenSpiritComponent},
	{path:'john-lennon-imagine', component:JohnLennonImagineComponent},
	{path:'magic-rude', component:MagicRudeComponent},
	{path:'jason-mraz', component:JasonMrazComponent},
	{path:'dire-straits-sultans-of-swing', component:DireStraitsSultansOfSwingComponent},
	{path:'passenger-let-her-go', component:PassengerLetHerGoComponent},
	{path:'bob-marley-no-woman-no-cry', component:BobMarleyNoWomanNoCryComponent},
	{path:'lady-gaga-shallow', component:LadyGagaShallowComponent}



];


export const routes: ModuleWithProviders = RouterModule.forRoot(router);