import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { routes } from './app.router';


import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SongsComponent } from './songs/songs.component';
import { ToPlayComponent } from './to-play/to-play.component';
import { MetalicaComponent } from './chords-songs/metalica/metalica.component';
import { EricLaylaComponent } from './chords-songs/eric-layla/eric-layla.component';
import { EdComponent } from './chords-songs/ed/ed.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { DuftPunkGetLuckyComponent } from './chords-songs/duft-punk-get-lucky/duft-punk-get-lucky.component';
import {BrunoVercacheComponent} from './chords-songs/bruno-vercache/bruno-vercache.component';
import { VisualChordsComponent } from './chords-songs/visual-chords/visual-chords.component';
import { HereComponent } from './chords-songs/here/here.component';
import { MeroonGirlsLikeYouComponent } from './chords-songs/meroon-girls-like-you/meroon-girls-like-you.component';
import { NatlieImbruTornComponent } from './chords-songs/natlie-imbru-torn/natlie-imbru-torn.component';
import { NoDoubtDontSpeakComponent } from './chords-songs/no-doubt-dont-speak/no-doubt-dont-speak.component';
import { TheCranberriesZombieComponent } from './chords-songs/the-cranberries-zombie/the-cranberries-zombie.component';
import { RedHotCPCalifornicationComponent } from './chords-songs/red-hot-cp-californication/red-hot-cp-californication.component';
import { BillWithersAintNoSunshineComponent } from './chords-songs/bill-withers-aint-no-sunshine/bill-withers-aint-no-sunshine.component';
import { Maroon5SheWillLoveComponent } from './chords-songs/maroon5-she-will-love/maroon5-she-will-love.component';
import { NirvanaSmileLikeTeenSpiritComponent } from './chords-songs/nirvana-smile-like-teen-spirit/nirvana-smile-like-teen-spirit.component';
import { JohnLennonImagineComponent } from './chords-songs/john-lennon-imagine/john-lennon-imagine.component';
import { MagicRudeComponent } from './chords-songs/magic-rude/magic-rude.component';
import { JasonMrazComponent } from './chords-songs/jason-mraz/jason-mraz.component';
import { DireStraitsSultansOfSwingComponent } from './chords-songs/dire-straits-sultans-of-swing/dire-straits-sultans-of-swing.component';
import { PassengerLetHerGoComponent } from './chords-songs/passenger-let-her-go/passenger-let-her-go.component';
import { BobMarleyNoWomanNoCryComponent } from './chords-songs/bob-marley-no-woman-no-cry/bob-marley-no-woman-no-cry.component';
import { LadyGagaShallowComponent } from './chords-songs/lady-gaga-shallow/lady-gaga-shallow.component';





@NgModule({
  declarations: [AppComponent, AboutComponent, ServicesComponent, ContactComponent,ContactComponent, LoginComponent, HomeComponent, SignUpComponent, SongsComponent, ToPlayComponent,  MetalicaComponent, EricLaylaComponent, EdComponent, LoadingSpinnerComponent, DuftPunkGetLuckyComponent,BrunoVercacheComponent, VisualChordsComponent, HereComponent, MeroonGirlsLikeYouComponent, NatlieImbruTornComponent, NoDoubtDontSpeakComponent, TheCranberriesZombieComponent, RedHotCPCalifornicationComponent, BillWithersAintNoSunshineComponent, Maroon5SheWillLoveComponent, NirvanaSmileLikeTeenSpiritComponent, JohnLennonImagineComponent, MagicRudeComponent, JasonMrazComponent, DireStraitsSultansOfSwingComponent, PassengerLetHerGoComponent, BobMarleyNoWomanNoCryComponent,  LadyGagaShallowComponent, ],
  
  imports: [BrowserModule,FormsModule,HttpModule,routes],  
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { };
